import React, {Component, createContext} from 'react';
import moment from 'moment';

export const PeriodContext = createContext();

const lastSaturdayOfMonth = (monthMoment) => {
    let lastDay = monthMoment.endOf('month').endOf('day');
    return lastDay.subtract((lastDay.day() + 1) % 7, 'days');
};

const firstSundayOfMonth = (dateString) => {
    let date = moment(dateString, "YYYY-MM-DD");
    let day = date.day();
    let diffDays = 0;

    if (day > 0) {
        diffDays = 7 - day;
    } else {
        diffDays = 0 - day
    }
    return date.add(diffDays, 'day').format("YYYY-MM-DD");
};

class PeriodContextProvider extends Component {
    state = {
        firstPeriodStart: moment.utc().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'),
        firstPeriodEnd: moment().format('YYYY-MM-DD'),
        secondPeriodStart: moment().subtract(23, 'months').startOf('month').format('YYYY-MM-DD'),
        secondPeriodEnd: moment(lastSaturdayOfMonth(moment().subtract(1, 'years').endOf('month'))).format('YYYY-MM-DD'),
        tempFirstPeriodStart: moment.utc().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'),
        tempFirstPeriodEnd: moment().format('YYYY-MM-DD'),
        tempSecondPeriodStart: moment().subtract(23, 'months').startOf('month').format('YYYY-MM-DD'),
        tempSecondPeriodEnd: moment(lastSaturdayOfMonth(moment().subtract(1, 'years').endOf('month'))).format('YYYY-MM-DD'),
        tempPeriodType: 'months',
        periodType: 'months',
        lastSubmit: moment(),
        datesSubmitting: false,
        datesSubmitted: null,
    };

    setTempPeriodState = (date, name) => {
        const {tempPeriodType} = this.state;
        if (moment(date).isAfter(moment())) {
            this.setState({
                [name]: moment().format('YYYY-MM-DD')
            });
        } else {
            if (tempPeriodType === 'months') {
                (name === 'tempFirstPeriodEnd' || name === 'tempSecondPeriodEnd') ?
                    this.setState({
                        [name]: moment(date).endOf('month').format('YYYY-MM-DD')
                    })
                    :
                    this.setState({
                        [name]: moment(date).startOf('month').format('YYYY-MM-DD')
                    });
            } else {
                this.setState({
                    [name]: moment(date).format('YYYY-MM-DD')
                });
            }
        }
    };

    setPeriodState = (callback) => {
        const {tempFirstPeriodStart, tempFirstPeriodEnd, tempSecondPeriodStart, tempSecondPeriodEnd, tempPeriodType} = this.state;
        this.setState({
            firstPeriodStart: tempFirstPeriodStart,
            firstPeriodEnd: tempFirstPeriodEnd,
            secondPeriodStart: tempSecondPeriodStart,
            secondPeriodEnd: tempSecondPeriodEnd,
            periodType: tempPeriodType,
        }, () => {
            if (callback) {
                callback()
            }
        })
    };

    resetDates = () => {
        this.setState({
            firstPeriodStart: null,
            firstPeriodEnd: null,
            secondPeriodStart: null,
            secondPeriodEnd: null
        })
    };

    // setDatesFromUrl = (p1s) => {
    //     this.setState({
    //         firstPeriodStart: p1s,
    //     })
    // };

    checkUrlMomentFormat = (date) => {
        return moment(date, 'YYYY-MM-DD', true).isValid()
    };

    setDatesFromUrl = (paramsObj) => {
        const {firstPeriodStart, firstPeriodEnd, secondPeriodStart, secondPeriodEnd} = this.state;
        this.setState({
            firstPeriodStart: paramsObj.p1s && this.checkUrlMomentFormat(paramsObj.p1s) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p1s).format('YYYY-MM-DD')
                    : moment(firstSundayOfMonth(moment(paramsObj.p1s))).format('YYYY-MM-DD')
                : firstPeriodStart,

            firstPeriodEnd: paramsObj.p1e && this.checkUrlMomentFormat(paramsObj.p1e) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p1e).format('YYYY-MM-DD')
                    : moment(lastSaturdayOfMonth(moment(paramsObj.p1e))).format('YYYY-MM-DD')
                : firstPeriodEnd,

            secondPeriodStart: paramsObj.p2s && this.checkUrlMomentFormat(paramsObj.p2s) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p2s).format('YYYY-MM-DD')
                    : moment(firstSundayOfMonth(moment(paramsObj.p2s))).format('YYYY-MM-DD')
                : secondPeriodStart,

            secondPeriodEnd: paramsObj.p2e && this.checkUrlMomentFormat(paramsObj.p2e) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p2e).format('YYYY-MM-DD')
                    : moment(lastSaturdayOfMonth(moment(paramsObj.p2e))).format('YYYY-MM-DD')
                : secondPeriodEnd,

            tempFirstPeriodStart: paramsObj.p1s && this.checkUrlMomentFormat(paramsObj.p1s) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p1s).format('YYYY-MM-DD')
                    : moment(firstSundayOfMonth(moment(paramsObj.p1s))).format('YYYY-MM-DD')
                : firstPeriodStart,

            tempFirstPeriodEnd: paramsObj.p1e && this.checkUrlMomentFormat(paramsObj.p1e) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p1e).format('YYYY-MM-DD')
                    : moment(lastSaturdayOfMonth(moment(paramsObj.p1e))).format('YYYY-MM-DD')
                : firstPeriodEnd,

            tempSecondPeriodStart: paramsObj.p2s && this.checkUrlMomentFormat(paramsObj.p2s) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p2s).format('YYYY-MM-DD')
                    : moment(firstSundayOfMonth(moment(paramsObj.p2s))).format('YYYY-MM-DD')
                : secondPeriodStart,

            tempSecondPeriodEnd: paramsObj.p2e && this.checkUrlMomentFormat(paramsObj.p2e) ?
                paramsObj.periodType === 'months' ?
                    moment(paramsObj.p2e).format('YYYY-MM-DD')
                    : moment(lastSaturdayOfMonth(moment(paramsObj.p2e))).format('YYYY-MM-DD')
                : secondPeriodEnd,

            periodType: paramsObj.periodType ? paramsObj.periodType : 'months',
            tempPeriodType: paramsObj.periodType ? paramsObj.periodType : 'months'
        })
    };


    setLastSubmit = () => {
        this.setState({lastSubmit: moment()})
    };

    setDatesSubmitting = () => {
        this.setState({datesSubmitting: new Date()})
    };

    setDateSubmitted = () => {
        this.setState({datesSubmitted: new Date()})
    };

    setPeriodType = (type) => {
        const {periodType, firstPeriodStart, secondPeriodStart, firstPeriodEnd, secondPeriodEnd, tempFirstPeriodEnd, tempFirstPeriodStart, tempSecondPeriodEnd, tempSecondPeriodStart} = this.state;
        if (periodType === 'months') {
            this.setState({
                tempPeriodType: type,
                // firstPeriodStart: moment(firstSundayOfMonth(tempFirstPeriodStart)).format('YYYY-MM-DD'),
                // firstPeriodEnd: moment(tempFirstPeriodEnd).format('YYYY-MM-DD'),
                // secondPeriodStart: moment(tempSecondPeriodStart).startOf('week').format('YYYY-MM-DD'),
                // secondPeriodEnd: moment(lastSaturdayOfMonth(moment(tempSecondPeriodEnd))).format('YYYY-MM-DD'),
                tempFirstPeriodStart: moment(firstSundayOfMonth(tempFirstPeriodStart)).format('YYYY-MM-DD'),
                tempFirstPeriodEnd: moment(tempFirstPeriodEnd).format('YYYY-MM-DD'),
                tempSecondPeriodStart: moment(firstSundayOfMonth(tempSecondPeriodStart)).startOf('week').format('YYYY-MM-DD'),
                tempSecondPeriodEnd: moment(lastSaturdayOfMonth(moment(tempSecondPeriodEnd))).format('YYYY-MM-DD'),
            })
        } else {
            this.setState({
                tempPeriodType: type,
                // firstPeriodStart: moment(tempFirstPeriodStart).startOf('month').format('YYYY-MM-DD'),
                // firstPeriodEnd: moment(tempFirstPeriodStart).endOf('month').format('YYYY-MM-DD'),
                // secondPeriodStart: moment(tempSecondPeriodStart).startOf('month').format('YYYY-MM-DD'),
                // secondPeriodEnd: moment(tempSecondPeriodEnd).endOf('month').format('YYYY-MM-DD'),
                tempFirstPeriodStart: moment(tempFirstPeriodStart).startOf('month').format('YYYY-MM-DD'),
                tempFirstPeriodEnd: moment(tempFirstPeriodEnd).endOf('month').format('YYYY-MM-DD'),
                tempSecondPeriodStart: moment(tempSecondPeriodStart).startOf('month').format('YYYY-MM-DD'),
                tempSecondPeriodEnd: moment(tempSecondPeriodEnd).endOf('month').format('YYYY-MM-DD'),
            })
        }
    };

    render() {
        return (
            <PeriodContext.Provider value={{
                ...this.state,
                setPeriodState: this.setPeriodState,
                setTempPeriodState: this.setTempPeriodState,
                resetDates: this.resetDates,
                setDateSubmitted: this.setDateSubmitted,
                setDatesSubmitting: this.setDatesSubmitting,
                setLastSubmit: this.setLastSubmit,
                setPeriodType: this.setPeriodType,
                setDatesFromUrl: this.setDatesFromUrl
            }}>
                {this.props.children}
            </PeriodContext.Provider>
        );
    }
}

export default PeriodContextProvider;
