import React, {Component, createContext} from 'react';

export const LocationContext = createContext();

class LocationContextProvider extends Component {
    state = {
        activeCountry: 'all',
        tempActiveCountry: 'all',
        activeState: 'all',
        tempActiveState: 'all',
    };


    setTempActiveCountry = (tempActiveCountry) => {
        this.setState({tempActiveCountry})
    };

    setTempActiveState = (tempActiveState) => {
        this.setState({tempActiveState})
    };

    applyTempLocationValues = () => {
        const {tempActiveCountry, tempActiveState} = this.state;
        this.setState({
            activeCountry: tempActiveCountry,
            activeState: tempActiveState
        })
    };

    getValuesFromUrl = (activeCountry, activeState) => {
        if(activeCountry !== undefined) {
            this.setState({
                activeCountry: activeCountry === 'undefined' ? 'all' : activeCountry,
                tempActiveCountry: activeCountry === 'undefined' ? 'all' : activeCountry,
                activeState: activeState === 'undefined' ? 'all' : activeState,
                tempActiveState: activeState === 'undefined' ? 'all' : activeState
            })
        }
    };

    resetLocationValues = () => {
        this.setState({
            activeCountry: 'all',
            tempActiveCountry: 'all',
            activeState: 'all',
            tempActiveState: 'all',
        })
    };

    render() {
        return (
            <LocationContext.Provider value={{
                ...this.state,
                // setActiveCountry: this.setActiveCountry,
                setTempActiveCountry: this.setTempActiveCountry,
                // setActiveState: this.setActiveState,
                setTempActiveState: this.setTempActiveState,
                applyTempLocationValues: this.applyTempLocationValues,
                getValuesFromUrl: this.getValuesFromUrl,
                resetLocationValues: this.resetLocationValues
            }}>
                {this.props.children}
            </LocationContext.Provider>
        );
    }
}

export default LocationContextProvider;
