import React, {createContext, useState, useEffect} from 'react';

export const SectionContext = createContext();

const SectionContextProvider = props => {
    const [initialLoad, setInitialLoad] = useState(false);
    const [sectionData, setSectionData] = useState([]);
    const [totalSubjectCount, setTotalSubjectCount] = useState([]);
    const [assignedSubjects, setAssignedSubjects] = useState([]);
    const [sectionsWithAllAssigned, setSectionsWithAllAssigned] = useState([]);

    useEffect(() => {
        initialLoad && handleAssignAll()
    }, [initialLoad])

    const handleAssignSubject = (sectionId, sectionName) => {
        let assigned = [...assignedSubjects];
        if (assigned.indexOf(sectionId) < 0) {
            assigned.push(sectionId)
        } else {
            assigned.splice(assigned.indexOf(sectionId), 1)
        }

        setAssignedSubjects(assigned);
    };

    const handleAssignAllInSection = (sectionName) => {
        let sectionToAssignFrom = sectionData.find(section => section.name === sectionName);
        let assigned = [...assignedSubjects];
        let fullSections = [...sectionsWithAllAssigned]
        if (fullSections.indexOf(sectionName) > -1) {
            sectionToAssignFrom.sections.map(subject => {
                if (assigned.indexOf(subject.section_id) > -1) {
                    let index = assigned.indexOf(subject.section_id);
                    assigned.splice(index, 1)
                }
            });
        } else {
            sectionToAssignFrom.sections.map(subject => {
                if (assigned.indexOf(subject.section_id) < 0) {
                    assigned.push(subject.section_id)
                }
            });
        }
        setAssignedSubjects(assigned)
    };

    const handleAssignAll = (data = null, callback) => {
        let fullData = data ? data : sectionData;

        let fullSections = [...sectionsWithAllAssigned];
        if (fullSections.length === fullData.length) {
            if(data) {
                return
            }
            setAssignedSubjects([]);
        } else {
            let assigned = [];
            fullData.map(section => {
                section.sections.map(subject => {
                    assigned.push(subject.section_id)
                });
            });
            setAssignedSubjects(assigned);
        }
        callback && callback();
    };

    const assignFromUrl = (arr, callback) => {
        let arrToNumbers = arr.map(str => Number(str));
        setAssignedSubjects(arrToNumbers)
        callback && callback();
    };

    const assignFromPanelFilter = (sectionName) => {
        if(sectionName === 'all') {
            handleAssignAll();
            return;
        }
        let sectionToAssignFrom = sectionData.find(section => section.name === sectionName);
        let assigned = [];
        sectionToAssignFrom.sections.map(subject => {
            assigned.push(subject.section_id)
        });
        setAssignedSubjects(assigned)
    };

    const resetSubjects = () => {
        let assigned = [];
        sectionData.map(section => {
            section.sections.map(subject => {
                assigned.push(subject.section_id)
            })
        });
        setAssignedSubjects(assigned)
    };

    useEffect(() => {
        let count = 0;
        sectionData.map(section => {
            section.sections.map(subject => {
                count++;
            })
        });
        setTotalSubjectCount(count)
    }, [sectionData]);

    useEffect(() => {
        let fullArrays = [];
        sectionData.map(section => {
            let count = 0;
            section.sections.map(subject => {
                if(assignedSubjects.indexOf(subject.section_id) > -1) {
                    count++
                }
            });
            if(count === section.sections.length) {
                fullArrays.push(section.name)
            }
        });
        setSectionsWithAllAssigned(fullArrays)
    }, [assignedSubjects])

    return (
        <SectionContext.Provider
            value={{
                sectionData,
                setSectionData,
                handleAssignSubject,
                assignedSubjects,
                handleAssignAllInSection,
                sectionsWithAllAssigned,
                handleAssignAll,
                assignFromUrl,
                setInitialLoad,
                totalSubjectCount,
                assignFromPanelFilter,
                resetSubjects
            }}>
            {props.children}
        </SectionContext.Provider>
    );
};

export default SectionContextProvider;
