import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {withRouter} from 'react-router-dom'
import {history} from "../../BrowserHistory";



export default function (ComposedComponent) {
    class Authenticate extends React.Component {
        componentWillMount() {
            const authToken = Cookies.get('authToken');
            if (!authToken || authToken === 'undefined') {
                Cookies.remove('authToken');
                Cookies.remove('authTokenExpiresAt');
                Cookies.remove('refreshToken');
                Cookies.remove('refreshTokenExpiresAt');
                this.props.history.push({
                    pathname: '/login',
                    state: { previousRoute: this.props.location.pathname }
                })
            }
        }

        componentWillUpdate(nextProps) {
            const authToken = Cookies.get('authToken');
            if (!authToken || authToken === 'undefined') {
                this.props.history.push({
                    pathname: '/login',
                    state: { previousRoute: this.props.location.pathname }
                })
            }
        }

        render() {
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }


    return withRouter(Authenticate);
}
