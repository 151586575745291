import React, {createContext, useState, useEffect} from 'react';
import moment from 'moment';

export const KPIPeriodContext = createContext();

const KPIPeriodContextProvider = props => {
    const [kpiPeriodStart, setKpiPeriodStart] = useState(moment().subtract(7,'d').format('YYYY-MM-DD'));
    const [kpiPeriodEnd, setKpiPeriodEnd] = useState(moment().format('YYYY-MM-DD'));
    const [tempKpiPeriodStart, setTempKpiPeriodStart] =  useState(moment().subtract(7,'d').format('YYYY-MM-DD'));
    const [tempKpiPeriodEnd, setTempKpiPeriodEnd] = useState(moment().format('YYYY-MM-DD'));
    const [quickSelectValue, setQuickSelectValue] = useState('last7');
    const [urlDatesFetched, setUrlDatesFetched] = useState(false);

    const handleDateSelect = (type, value) => {
        if(type === 'start') {
            setTempKpiPeriodStart(moment(value).format('YYYY-MM-DD'))
        } else {
            setTempKpiPeriodEnd(moment(value).format('YYYY-MM-DD'))
        }
        setQuickSelectValue('custom')
    };

    const submitKpiDates = () => {
        setKpiPeriodStart(tempKpiPeriodStart);
        setKpiPeriodEnd(tempKpiPeriodEnd);
    };

    const handleDatesFromUrl = (params) => {
        if(params.start && params.end) {
            setKpiPeriodStart(params.start);
            setTempKpiPeriodStart(params.start);
            setKpiPeriodEnd(params.end);
            setTempKpiPeriodEnd(params.end);
            setQuickSelectValue('custom');
        }
        setUrlDatesFetched(true)
    };

    const handleQuickSelectValue = (value) => {
        switch(value) {
            case 'today':
                setTempKpiPeriodStart(moment().format('YYYY-MM-DD'));
                setTempKpiPeriodEnd(moment().format('YYYY-MM-DD'));
                break;
            case 'yesterday':
                setTempKpiPeriodStart(moment().subtract(1,'d').format('YYYY-MM-DD'));
                setTempKpiPeriodEnd(moment().subtract(1,'d').format('YYYY-MM-DD'));
                break;
            case 'last7':
                setTempKpiPeriodStart(moment().subtract(7,'d').format('YYYY-MM-DD'));
                setTempKpiPeriodEnd(moment().format('YYYY-MM-DD'));
                break;
            case 'last30':
                setTempKpiPeriodStart(moment().subtract(30,'d').format('YYYY-MM-DD'));
                setTempKpiPeriodEnd(moment().format('YYYY-MM-DD'));
                break;
            default:
                break;
        }
        setQuickSelectValue(value)
    };

    return (
        <KPIPeriodContext.Provider
            value={{
                kpiPeriodStart,
                kpiPeriodEnd,
                tempKpiPeriodStart,
                tempKpiPeriodEnd,
                handleQuickSelectValue,
                handleDateSelect,
                submitKpiDates,
                quickSelectValue,
                setKpiPeriodStart,
                setKpiPeriodEnd,
                setTempKpiPeriodStart,
                setTempKpiPeriodEnd,
                setQuickSelectValue,
                setUrlDatesFetched,
                urlDatesFetched,
                handleDatesFromUrl
            }}>
            {props.children}
        </KPIPeriodContext.Provider>
    );
};

export default KPIPeriodContextProvider;

// curl -X GET 'https://jove-mobile-api-dev.dataprodev.com/api/video/init/10/50' -H "Accept: application/json" -H "Authorization: L24qw2bvS1BxUz5ELZjtLQWEfK1VNPNo"
