import React, {Component, createContext} from 'react';

export const ViewContext = createContext();

class ViewContextProvider extends Component {
    state = {
        activeView: 'dashboard',
        activeInstitution: null,
        activeSubject: null,
        activeVideo: null,
        activeSubcategory: null,
    };

    setActiveView = (activeView) => {
        this.setState({activeView})
    };

    setActiveInstitution = (activeInstitution) => {
        this.setState({activeInstitution})
    };

    setActiveSubject = (activeSubject) => {
        this.setState({activeSubject})
    };

    setActiveVideo = (activeVideo) => {
        this.setState({activeVideo})
    };

    setActiveSubcategory = (activeSubcategory) => {
        this.setState({activeSubcategory})
    };

    handleInstitutionSelect = (name, id) => {
        window.scrollTo(0, 0);
        this.setActiveInstitution({name, id});
        this.setActiveView('institution')
    };

    handleInstitutionSubjectSelect = (name, id, views, type) => {
        window.scrollTo(0, 0);
        this.setActiveSubject({name, id, views});
        this.setActiveView('institution-subject')
    };

    handleSubjectSelect = (name, id, views, type) => {
        window.scrollTo(0, 0);
        this.setActiveSubject({name, id, views});
        this.setActiveInstitution(null);
        this.setActiveView('subject')
    };

    handleVideoSelect = (name, id, views, type) => {
        window.scrollTo(0, 0);
        this.setActiveVideo({name, id, views});
        this.setActiveInstitution(null);
        this.setActiveView('video')
    };

    handleInstitutionsExpand = () => {
        window.scrollTo(0, 0);
        this.setActiveView('institutions-expanded')
    };

    handleSubjectsExpand = () => {
        window.scrollTo(0, 0);
        this.setActiveView('subjects-expanded')
    };

    handleVideosExpand = () => {
        window.scrollTo(0, 0);
        this.setActiveView('videos-expanded')
    };

    handleInstitutionSubcategoryExpand = (category) => {
        window.scrollTo(0, 0);
        this.setActiveSubcategory(category);
        this.setActiveView('subcategory-expanded')
    };

    render() {
        return (
            <ViewContext.Provider value={{
                ...this.state,
                setActiveView: this.setActiveView,
                setActiveInstitution: this.setActiveInstitution,
                setActiveSubject: this.setActiveSubject,
                setActiveSubcategory: this.setActiveSubcategory,
                setActiveVideo: this.setActiveVideo,
                handleInstitutionSelect: this.handleInstitutionSelect,
                handleSubjectSelect: this.handleSubjectSelect,
                handleVideoSelect: this.handleVideoSelect,
                handleVideosExpand: this.handleVideosExpand,
                handleInstitutionsExpand: this.handleInstitutionsExpand,
                handleSubjectsExpand: this.handleSubjectsExpand,
                handleInstitutionSubcategoryExpand: this.handleInstitutionSubcategoryExpand,
                handleInstitutionSubjectSelect: this.handleInstitutionSubjectSelect,
            }}
            >
                {this.props.children}
            </ViewContext.Provider>
        );
    }
}

export default ViewContextProvider;
