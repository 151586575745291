import React, {useState} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import './App.scss';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import RequireAuth from './Components/RequireAuth'
import RequireGuest from './Components/RequireGuest'
import ScrollToTop from './Components/ScrollToTop'
import PeriodContextProvider from './contexts/PeriodContext'
import SectionContextProvider from './contexts/SectionContextNew';
import ViewContextProvider from './contexts/ViewContext';
import LocationContextProvider from './contexts/LocationContext';
import KPIPeriodContextProvider from './contexts/KPIPeriodContext';
import {history} from './BrowserHistory';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));
const KPI = React.lazy(() => import('./containers/KPI'));

// Pages
const Login = React.lazy(() => import('./containers/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const App = (context) => {
    const [state, setState] = useState({
        authToken: null,
        tokenExpiresAt: null,
        refreshToken: null,
        refreshTokenExpiresAt: null
    });

    return (
        <Router history={history}>
            <ScrollToTop>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <PeriodContextProvider>
                        <SectionContextProvider>
                            <ViewContextProvider>
                                <LocationContextProvider>
                                    <KPIPeriodContextProvider>
                                        <React.Suspense fallback={loading()}>
                                            <Switch>
                                                <Route exact path="/login" name="Home" component={RequireGuest(Login)}/>
                                                <Route path="/register" name="Register Page"
                                                       component={RequireGuest(Register)}/>
                                                <Route exact path="/404" name="Page 404"
                                                       render={props => <Page404 {...props}/>}/>
                                                <Route exact path="/500" name="Page 500"
                                                       render={props => <Page500 {...props}/>}/>
                                                <Route path="/kpi" name="KPI" component={RequireAuth(KPI)}/>
                                                <Route path="/" name="Home" component={RequireAuth(Layout)}/>
                                            </Switch>
                                        </React.Suspense>
                                    </KPIPeriodContextProvider>
                                </LocationContextProvider>
                            </ViewContextProvider>
                        </SectionContextProvider>
                    </PeriodContextProvider>
                </MuiPickersUtilsProvider>
            </ScrollToTop>
        </Router>
    );
};

export default App;
